var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      },
      [_vm._v("Fetching tags...")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "related-tags flex-wrap" },
      _vm._l(_vm.splitTags, function(sTags, i) {
        return _c(
          "div",
          { key: i, staticClass: "related-items" },
          _vm._l(sTags, function(tag, $idx) {
            return _c("tag-preview", { key: $idx, attrs: { tag: tag } })
          }),
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.close($event)
            }
          }
        },
        [_vm._v("Close Preview")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }