var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.preview.show,
            expression: "!preview.show"
          }
        ]
      },
      [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.tags,
              expression: "tags"
            }
          ],
          ref: "otherTags",
          staticClass: "tag-textarea",
          attrs: {
            id: "post_tag_string",
            rows: "5",
            "data-autocomplete": "tag-edit",
            name: "post[tag_string]",
            spellcheck: "false"
          },
          domProps: { value: _vm.tags },
          on: {
            keyup: _vm.updateTagCount,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.tags = $event.target.value
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.preview.show,
            expression: "preview.show"
          }
        ]
      },
      [
        _c("tag-preview", {
          attrs: { tags: _vm.preview.tags, loading: _vm.preview.loading },
          on: { close: _vm.previewFinalTags }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "related-tag-functions" }, [
      _vm._v("\n        Related:\n        "),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.findRelated()
            }
          }
        },
        [_vm._v("Tags")]
      ),
      _vm._v(" |\n        "),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.findRelated("artist")
            }
          }
        },
        [_vm._v("Artists")]
      ),
      _vm._v(" |\n        "),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.findRelated("copyright")
            }
          }
        },
        [_vm._v("Copyrights")]
      ),
      _vm._v(" |\n        "),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.findRelated("char")
            }
          }
        },
        [_vm._v("Characters")]
      ),
      _vm._v(" |\n        "),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.findRelated("species")
            }
          }
        },
        [_vm._v("Species")]
      ),
      _vm._v(" |\n        "),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.findRelated("meta")
            }
          }
        },
        [_vm._v("Metatags")]
      ),
      _vm._v(" |\n        "),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.previewFinalTags($event)
            }
          }
        },
        [_vm._v("Preview Final Tags")]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("h3", [
          _vm._v("Related Tags "),
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toggleRelated($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.relatedText))]
          )
        ]),
        _vm._v(" "),
        _c("related-tags", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expandRelated,
              expression: "expandRelated"
            }
          ],
          attrs: {
            tags: _vm.tagsArray,
            related: _vm.relatedTags,
            loading: _vm.loadingRelated
          },
          on: { "tag-active": _vm.pushTag }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }