var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "related-tags flex-wrap" },
    _vm._l(_vm.tagGroups, function(group) {
      return _c(
        "div",
        { key: group.title, staticClass: "related-section" },
        _vm._l(_vm.splitTags(group.tags), function(tags, i) {
          return _c(
            "div",
            { key: i, staticClass: "related-items" },
            [
              i === 0
                ? _c("div", { staticClass: "related-title" }, [
                    _vm._v(_vm._s(group.title))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(tags, function(tag) {
                return _c("div", { key: tag[0], staticClass: "related-item" }, [
                  _c(
                    "a",
                    {
                      class: _vm.tagClasses(tag),
                      attrs: { href: _vm.tagLink(tag) },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggle(tag)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tag[0]))]
                  )
                ])
              })
            ],
            2
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }