import Utility from './utility'

let Autocomplete = {};

Autocomplete.METATAGS = ["-user","user","-approver","approver","commenter","comm","noter","noteupdater","artcomm","-pool","pool","ordpool","-fav","fav","-favoritedby","favoritedby","md5","-rating","rating","note","-note","-locked","locked","width","height","mpixels","ratio","score","favcount","filesize","source","-source","id","-id","date","age","order","limit","-status","status","tagcount","parent","-parent","child","pixiv_id","pixiv","search","upvote","downvote","voted","filetype","-filetype","flagger","type","-type","-flagger","appealer","-appealer","disapproval","-disapproval","set","-set","randseed","-voted","-upvote","-downvote","description","-description","change","-user_id","user_id","delreason","-delreason","deletedby","-deletedby","votedup","voteddown","-votedup","-voteddown","duration","gentags","spectags","chartags","copytags","arttags","invtags","lortags","metatags","comment_count","note_count","flag_count","child_count","pool_count","hassource","hasdescription","ratinglocked","notelocked","statuslocked","tagslocked","hideanon","hidegoogle","isparent","ischild","inpool"];
Autocomplete.TAG_CATEGORIES = {"ch":4,"co":3,"gen":0,"spec":5,"char":4,"copy":3,"art":1,"inv":6,"lor":8,"meta":7,"general":0,"species":5,"character":4,"copyright":3,"artist":1,"invalid":6,"lore":8};
Autocomplete.ORDER_METATAGS = ["id","id_desc","score","score_asc","favcount","favcount_asc","created_at","created_at_asc","updated","updated_desc","updated_asc","comment","comment_asc","comment_bumped","comment_bumped_asc","note","note_asc","artcomm","artcomm_asc","mpixels","mpixels_asc","portrait","landscape","filesize","filesize_asc","tagcount","tagcount_asc","change","change_desc","change_asc","duration","duration_desc","duration_asc","rank","random","custom","comment_count","note_count","flag_count","child_count","pool_count","gentags","gentags_asc","spectags","spectags_asc","chartags","chartags_asc","copytags","copytags_asc","arttags","arttags_asc","invtags","invtags_asc","lortags","lortags_asc","metatags","metatags_asc"];

Autocomplete.TAG_PREFIXES = "-|~|" + Object.keys(Autocomplete.TAG_CATEGORIES).map(category => category + ":").join("|");
Autocomplete.TAG_PREFIXES_REGEX = new RegExp("^(" + Autocomplete.TAG_PREFIXES + ")(.*)$", "i");
Autocomplete.METATAGS_REGEX = new RegExp("^(" + Autocomplete.METATAGS.join("|") + "):(.*)$", "i");

Autocomplete.initialize_all = function() {
  if (Utility.meta("enable-auto-complete") !== "true")
    return;

  $.widget("ui.autocomplete", $.ui.autocomplete, {
    options: {
      delay: 225,
      minLength: 3,
      autoFocus: false,
      focus: function() { return false; },
    },
    _create: function() {
      this.element.on("keydown.Autocomplete.tab", null, "tab", Autocomplete.on_tab);
      this._super();
    },
    _renderItem: Autocomplete.render_item
  });

  this.initialize_tag_autocomplete();
  this.initialize_artist_autocomplete($('[data-autocomplete="artist"]'));
  this.initialize_pool_autocomplete($('[data-autocomplete="pool"]'));
  this.initialize_user_autocomplete($('[data-autocomplete="user"]'));
  this.initialize_wiki_autocomplete($('[data-autocomplete="wiki-page"]'));
}

Autocomplete.initialize_tag_autocomplete = function() {
  var $fields_multiple = $('[data-autocomplete="tag-query"], [data-autocomplete="tag-edit"]');
  var $fields_single = $('[data-autocomplete="tag"]');

  $fields_multiple.autocomplete({
    search: function() {
      if ($(this).data("ui-autocomplete")) {
        $(this).data("ui-autocomplete").menu.bindings = $();
      }
    },
    select: function(event, ui) {
      // Prevent Upload.initialize_enter_on_tags from running if the
      // Enter key is used to select a tag from the autocomplete menu.
      if (event.key === "Enter") {
        event.stopImmediatePropagation();
      }

      Autocomplete.insert_completion(this, ui.item.value);
      return false;
    },
    source: function(req, resp) {
      var query = Autocomplete.parse_query(req.term, this.element.get(0).selectionStart);
      var metatag = query.metatag;
      var term = query.term;

      if (!metatag && !term) {
        this.close();
        return;
      }

      switch (metatag) {
      case "md5":
      case "width":
      case "height":
      case "mpixels":
      case "ratio":
      case "score":
      case "duration":
      case "favcount":
      case "filesize":
      case "source":
      case "id":
      case "date":
      case "age":
      case "limit":
      case "tagcount":
      
        case "gentags": // eslint-disable-line
      
        case "spectags": // eslint-disable-line
      
        case "chartags": // eslint-disable-line
      
        case "copytags": // eslint-disable-line
      
        case "arttags": // eslint-disable-line
      
        case "invtags": // eslint-disable-line
      
        case "lortags": // eslint-disable-line
      
        case "metatags": // eslint-disable-line
      
        resp([]);
        return;
      case "order":
      case "status":
      case "rating":
      case "locked":
      case "child":
      case "parent":
      case "filetype":
      case "type":
      case "disapproval":
        Autocomplete.static_metatag_source(term, resp, metatag);
        return;
      case "user":
      case "approver":
      case "commenter":
      case "comm":
      case "noter":
      case "noteupdater":
      case "artcomm":
      case "fav":
      case "favoritedby":
      case "appealer":
      case "flagger":
      case "upvote":
      case "downvote":
        Autocomplete.user_source(term, resp, metatag + ":");
        break;
      case "pool":
        Autocomplete.pool_source(term, resp, metatag);
        break;
      default:
        Autocomplete.normal_source(term, resp);
        break;
      }
    }
  });

  $fields_single.autocomplete({
    search: function() {
      $(this).data("ui-autocomplete").menu.bindings = $();
    },
    source: function(req, resp) {
      Autocomplete.normal_source(req.term, resp);
    }
  });
}

Autocomplete.initialize_artist_autocomplete = function($fields) {
  $fields.autocomplete({
    search: function() {
      $(this).data("ui-autocomplete").menu.bindings = $();
    },
    source: function(req, resp) {
      $.ajax({
        url: "/artists.json",
        data: {
          "search[name_like]": req.term.trim().replace(/\s+/g, "_") + "*",
          "search[is_active]": true,
          "search[order]": "post_count",
          "limit": 10,
          "expiry": 7
        },
        method: "get",
        success: function(data) {
          resp($.map(data, function(artist) {
            return {
              type: "tag",
              label: artist.name.replace(/_/g, " "),
              value: artist.name,
              category: Autocomplete.TAG_CATEGORIES.artist,
            };
          }));
        }
      });
    }
  });
};

Autocomplete.initialize_pool_autocomplete = function($fields) {
  $fields.autocomplete({
    search: function() {
      $(this).data("ui-autocomplete").menu.bindings = $();
    },
    source: function(req, resp) {
      Autocomplete.pool_source(req.term, resp);
    },
  });
};

Autocomplete.initialize_user_autocomplete = function($fields) {
  $fields.autocomplete({
    search: function() {
      $(this).data("ui-autocomplete").menu.bindings = $();
    },
    source: function(req, resp) {
      Autocomplete.user_source(req.term, resp, "");
    },
  });
};

Autocomplete.initialize_wiki_autocomplete = function($fields) {
  $fields.autocomplete({
    search: function() {
      $(this).data("ui-autocomplete").menu.bindings = $();
    },
    source: function(req, resp) {
      $.ajax({
        url: "/wiki_pages.json",
        data: {
          "search[title]": req.term + "*",
          "search[hide_deleted]": "Yes",
          "search[order]": "post_count",
          "limit": 10,
          "expiry": 7
        },
        method: "get",
        success: function(data) {
          resp($.map(data, function(wiki_page) {
            return {
              type: "tag",
              label: wiki_page.title.replace(/_/g, " "),
              value: wiki_page.title,
              category: wiki_page.category_name
            };
          }));
        }
      });
    }
  });
};

Autocomplete.normal_source = function(term, resp) {
  if (term.length < 3) {
    return;
  }
  return $.ajax({
    url: "/tags/autocomplete.json",
    data: {
      "search[name_matches]": term,
      "expiry": 7
    },
    method: "get",
    success: function(data) {
      var d = $.map(data, function(tag) {
        return {
          type: "tag",
          label: tag.name.replace(/_/g, " "),
          antecedent: tag.antecedent_name,
          value: tag.name,
          category: tag.category,
          source: tag.source,
          weight: tag.weight,
          post_count: tag.post_count
        };
      });

      resp(d);
    }
  });
}

Autocomplete.parse_query = function(text, caret) {
  var metatag = "";
  var term = "";

  var before_caret_text = text.substring(0, caret);
  var match = before_caret_text.match(/\S+$/g);
  if (match) {
    term = match[0];
  } else {
    return {};
  }

  match = term.match(Autocomplete.TAG_PREFIXES_REGEX);
  if (match) {
    metatag = match[1].toLowerCase();
    term = match[2];
  }

  match = term.match(Autocomplete.METATAGS_REGEX);
  if (match) {
    metatag = match[1].toLowerCase();
    term = match[2];
  }

  return { metatag: metatag, term: term };
};

// Update the input field with the item currently focused in the
// autocomplete menu, then position the caret just after the inserted completion.
Autocomplete.insert_completion = function(input, completion) {
  var before_caret_text = input.value.substring(0, input.selectionStart).trim();
  var after_caret_text = input.value.substring(input.selectionStart).trim();

  var regexp = new RegExp("(" + Autocomplete.TAG_PREFIXES + ")?\\S+$", "g");
  before_caret_text = before_caret_text.replace(regexp, "$1") + completion + " ";

  input.value = before_caret_text + after_caret_text + " ";
  input.selectionStart = input.selectionEnd = before_caret_text.length;

  // Force watched fields to fire input change callbacks.
  const event = new Event('input', {bubbles: true});
  input.dispatchEvent(event);
};

// If we press tab while the autocomplete menu is open but nothing is
// focused, complete the first item and close the menu.
Autocomplete.on_tab = function(event) {
  var input = this;
  var autocomplete = $(input).autocomplete("instance");
  var $autocomplete_menu = autocomplete.menu.element;

  if (!$autocomplete_menu.is(":visible")) {
    return;
  }

  if ($autocomplete_menu.has(".ui-state-active").length === 0) {
    var $first_item = $autocomplete_menu.find(".ui-menu-item").first();
    var completion = $first_item.data().uiAutocompleteItem.value;

    Autocomplete.insert_completion(input, completion);
    autocomplete.close();
  }

  // Prevent the tab key from moving focus to the next element.
  event.preventDefault();
};

Autocomplete.render_item = function(list, item) {
  var $link = $("<a/>");
  $link.text(item.label);
  $link.attr("href", "/posts?tags=" + encodeURIComponent(item.value));
  $link.on("click.danbooru", function(e) {
    e.preventDefault();
  });

  if (item.antecedent) {
    var antecedent = item.antecedent.replace(/_/g, " ");
    var arrow = $("<span/>").html(" &rarr; ").addClass("autocomplete-arrow");
    var antecedent_element = $("<span/>").text(antecedent).addClass("autocomplete-antecedent");
    $link.prepend([
      antecedent_element,
      arrow
    ]);
  }

  if (item.post_count !== undefined) {
    var count = item.post_count;

    if (count >= 1000) {
      count = Math.floor(count / 1000) + "k";
    }

    var $post_count = $("<span/>").addClass("post-count").css("float", "right").text(count);
    $link.append($post_count);
  }

  if (item.type === "tag") {
    $link.addClass("tag-type-" + item.category);
  } else if (item.type === "user") {
    var level_class = "user-" + item.level.toLowerCase();
    $link.addClass(level_class);
    if (Utility.meta("style-usernames") === "true") {
      $link.addClass("with-style");
    }
  } else if (item.type === "pool") {
    $link.addClass("pool-category-" + item.category);
  }

  var $menu_item = $("<div/>").append($link);
  var $list_item = $("<li/>").data("item.autocomplete", item).append($menu_item);

  var data_attributes = ["type", "source", "antecedent", "value", "category", "post_count", "weight"];
  data_attributes.forEach(attr => {
    $list_item.attr(`data-autocomplete-${attr.replace(/_/g, "-")}`, item[attr]);
  });

  return $list_item.appendTo(list);
};

Autocomplete.static_metatags = {
  order: Autocomplete.ORDER_METATAGS,
  status: [
    "any", "deleted", "active", "pending", "flagged", "modqueue", "unmoderated"
  ],
  rating: [
    "safe", "questionable", "explicit"
  ],
  locked: [
    "rating", "note", "status"
  ],
  child: [
    "any", "none"
  ],
  parent: [
    "any", "none"
  ],
  filetype: [
    "jpg", "png", "gif", "swf", "zip", "webm", "mp4"
  ],
  type: [
    "jpg", "png", "gif", "swf", "zip", "webm", "mp4"
  ],
  disapproval: [
    "any", "none", "borderline_quality", "borderline_relevancy", "other"
  ]
}

Autocomplete.static_metatag_source = function(term, resp, metatag) {
  var sub_metatags = this.static_metatags[metatag];

  var matches = sub_metatags.filter(sub_metatag => sub_metatag.startsWith(term.toLowerCase()));
  matches = matches.map(sub_metatag => `${metatag}:${sub_metatag}`).sort().slice(0, 10);

  resp(matches);
}

Autocomplete.user_source = function(term, resp, prefix) {
  return $.ajax({
    url: "/users.json",
    data: {
      "search[order]": "post_upload_count",
      "search[name_matches]": term + "*",
      "limit": 10
    },
    method: "get",
    success: function(data) {
      resp($.map(data, function(user) {
        return {
          type: "user",
          label: user.name.replace(/_/, " "),
          value: prefix + user.name,
          level: user.level_string
        };
      }));
    }
  });
}

Autocomplete.pool_source = function(term, resp, metatag) {
  return $.ajax({
    url: "/pools.json",
    data: {
      "search[order]": "post_count",
      "search[name_matches]": term,
      "limit": 10
    },
    method: "get",
    success: function(data) {
      resp($.map(data, function(pool) {
        return {
          type: "pool",
          label: pool.name.replace(/_/g, " "),
          value: (metatag ? (metatag + ":" + pool.name) : pool.name),
          post_count: pool.post_count,
          category: pool.category
        };
      }));
    }
  });
}

$(document).ready(function() {
  Autocomplete.initialize_all();
});

export default Autocomplete;

