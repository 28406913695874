var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-grid-outer" }, [
    _c(
      "div",
      { staticClass: "col box-section", staticStyle: { flex: "2 0 0" } },
      [
        _c("div", {
          staticClass: "the_secret_switch",
          on: { click: _vm.toggleNormalMode }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.overDims,
                expression: "overDims"
              }
            ],
            staticClass: "box-section sect_red"
          },
          [
            _vm._v(
              "\n            One of the image dimensions is above the maximum allowed of 15,000px and will fail to upload.\n        "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid border-bottom" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col2" }, [
            !_vm.disableFileUpload
              ? _c("div", [
                  _c("label", [
                    _vm._v("File:\n                        "),
                    _c("input", {
                      ref: "post_file",
                      attrs: { type: "file", disabled: _vm.disableFileUpload },
                      on: {
                        change: _vm.updatePreview,
                        keyup: _vm.updatePreview
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.disableURLUpload,
                          expression: "disableURLUpload"
                        }
                      ],
                      on: { click: _vm.clearFile }
                    },
                    [_vm._v("Clear")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.disableURLUpload
              ? _c("div", [
                  _vm.badDirectURL
                    ? _c("div", { staticClass: "box-section sect_red" }, [
                        _vm._v(
                          "\n                        The direct URL entered has the following problem: " +
                            _vm._s(_vm.directURLProblem)
                        ),
                        _c("br"),
                        _vm._v("\n                        You should review "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/wiki/show/howto:sites_and_sources"
                            }
                          },
                          [_vm._v("the sourcing guide")]
                        ),
                        _vm._v(".\n                    ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("label", [
                    _vm._v(
                      _vm._s(!_vm.disableFileUpload ? "(or) " : "") +
                        "URL:\n                        "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uploadURL,
                          expression: "uploadURL"
                        }
                      ],
                      attrs: {
                        type: "text",
                        size: "50",
                        disabled: _vm.disableURLUpload
                      },
                      domProps: { value: _vm.uploadURL },
                      on: {
                        keyup: _vm.updatePreview,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.uploadURL = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.whitelist.visible,
                          expression: "whitelist.visible"
                        }
                      ],
                      class: {
                        "whitelist-warning-allowed": _vm.whitelist.allowed,
                        "whitelist-warning-disallowed": !_vm.whitelist.allowed
                      },
                      attrs: { id: "whitelist-warning" }
                    },
                    [
                      _vm.whitelist.allowed
                        ? _c("span", [
                            _vm._v("Uploads from "),
                            _c("b", [_vm._v(_vm._s(_vm.whitelist.domain))]),
                            _vm._v(" are permitted.")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.whitelist.allowed
                        ? _c("span", [
                            _vm._v("Uploads from "),
                            _c("b", [_vm._v(_vm._s(_vm.whitelist.domain))]),
                            _vm._v(
                              " are not permitted.\n                            "
                            ),
                            _vm.whitelist.reason
                              ? _c("span", [
                                  _vm._v(
                                    "Reason given: " +
                                      _vm._s(_vm.whitelist.reason)
                                  )
                                ])
                              : _vm._e(),
                            _vm._v("\n                            ("),
                            _c("a", { attrs: { href: "/upload_whitelists" } }, [
                              _vm._v("View whitelisted domains")
                            ]),
                            _vm._v(")")
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "box-section upload_preview_container in-editor below-upload"
          },
          [
            _c("div", { staticClass: "upload_preview_dims" }, [
              _vm._v(_vm._s(_vm.previewDimensions))
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "upload_preview_img",
              staticStyle: { "max-width": "100%" },
              attrs: { src: _vm.previewURL, referrerpolicy: "no-referrer" }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid border-bottom" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "col2" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showErrors && _vm.sourceWarning,
                    expression: "showErrors && sourceWarning"
                  }
                ],
                staticClass: "box-section sect_red"
              },
              [
                _vm._v(
                  "\n                    A source must be provided or you must select that there is no available source.\n                "
                )
              ]
            ),
            _vm._v(" "),
            !_vm.noSource
              ? _c(
                  "div",
                  _vm._l(_vm.sources, function(s, i) {
                    return _c("image-source", {
                      key: i,
                      attrs: { last: i === _vm.sources.length - 1, index: i },
                      on: {
                        delete: function($event) {
                          return _vm.removeSource(i)
                        },
                        add: _vm.addSource
                      },
                      model: {
                        value: _vm.sources[i],
                        callback: function($$v) {
                          _vm.$set(_vm.sources, i, $$v)
                        },
                        expression: "sources[i]"
                      }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("label", { staticClass: "section-label" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.noSource,
                      expression: "noSource"
                    }
                  ],
                  attrs: { type: "checkbox", id: "no_source" },
                  domProps: {
                    checked: Array.isArray(_vm.noSource)
                      ? _vm._i(_vm.noSource, null) > -1
                      : _vm.noSource
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.noSource,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.noSource = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.noSource = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.noSource = $$c
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n                        No available source / I am the source.\n                    "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.normalMode
          ? [
              _c("div", { staticClass: "flex-grid border-bottom" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "col2" }, [
                  _c("div", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tagEntries.character,
                          expression: "tagEntries.character"
                        }
                      ],
                      staticClass: "tag-textarea",
                      attrs: {
                        id: "post_characters",
                        rows: "2",
                        placeholder: "Ex: artist_name etc.",
                        "data-autocomplete": "tag-edit"
                      },
                      domProps: { value: _vm.tagEntries.character },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.tagEntries,
                            "character",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-wrap" },
                    _vm._l(_vm.checkboxes.artist, function(check) {
                      return _c("image-checkbox", {
                        key: check.name,
                        attrs: {
                          check: check,
                          checks: _vm.checkboxes.selected
                        },
                        on: { set: _vm.setCheck }
                      })
                    }),
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grid border-bottom" }, [
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "col2" }, [
                  _c(
                    "div",
                    { staticClass: "flex-wrap" },
                    _vm._l(_vm.checkboxes.sex, function(check) {
                      return _c("image-checkbox", {
                        key: check.name,
                        attrs: {
                          check: check,
                          checks: _vm.checkboxes.selected
                        },
                        on: { set: _vm.setCheck }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-wrap" },
                    _vm._l(_vm.checkboxes.count, function(check) {
                      return _c("image-checkbox", {
                        key: check.name,
                        attrs: {
                          check: check,
                          checks: _vm.checkboxes.selected
                        },
                        on: { set: _vm.setCheck }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-wrap" },
                    _vm._l(_vm.checkboxes.pairing, function(check) {
                      return _c("image-checkbox", {
                        key: check.name,
                        attrs: {
                          check: check,
                          checks: _vm.checkboxes.selected
                        },
                        on: { set: _vm.setCheck }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tagEntries.sex,
                        expression: "tagEntries.sex"
                      }
                    ],
                    staticClass: "tag-textarea",
                    attrs: {
                      rows: "2",
                      id: "post_sexes",
                      placeholder: "Ex: character_name solo_focus etc.",
                      "data-autocomplete": "tag-edit"
                    },
                    domProps: { value: _vm.tagEntries.sex },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.tagEntries, "sex", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grid border-bottom" }, [
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "col2" }, [
                  _c(
                    "div",
                    { staticClass: "flex-wrap" },
                    _vm._l(_vm.checkboxes.body, function(check) {
                      return _c("image-checkbox", {
                        key: check.name,
                        attrs: {
                          check: check,
                          checks: _vm.checkboxes.selected
                        },
                        on: { set: _vm.setCheck }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tagEntries.bodyType,
                        expression: "tagEntries.bodyType"
                      }
                    ],
                    staticClass: "tag-textarea",
                    attrs: {
                      rows: "2",
                      id: "post_bodyTypes",
                      placeholder: "Ex: bear dragon hyena rat newt etc.",
                      "data-autocomplete": "tag-edit"
                    },
                    domProps: { value: _vm.tagEntries.bodyType },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.tagEntries,
                          "bodyType",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grid border-bottom" }, [
                _vm._m(5),
                _vm._v(" "),
                _c("div", { staticClass: "col2" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tagEntries.theme,
                        expression: "tagEntries.theme"
                      }
                    ],
                    staticClass: "tag-textarea",
                    attrs: {
                      id: "post_themes",
                      rows: "2",
                      "data-autocomplete": "tag-edit",
                      placeholder:
                        "Ex: cub young gore scat watersports diaper my_little_pony vore not_furry rape hyper etc."
                    },
                    domProps: { value: _vm.tagEntries.theme },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.tagEntries, "theme", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid border-bottom" }, [
          _vm._m(6),
          _vm._v(" "),
          _c("div", { staticClass: "col2" }, [
            _vm.showErrors && _vm.invalidRating
              ? _c("div", { staticClass: "box-section sect_red" }, [
                  _vm._v(
                    "\n                    You must select an appropriate rating for this image.\n                "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              [
                !_vm.safe
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "toggle-button rating-e",
                          class: { active: _vm.rating === "e" },
                          on: {
                            click: function($event) {
                              _vm.rating = "e"
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                            Explicit\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "toggle-button rating-q",
                          class: { active: _vm.rating === "q" },
                          on: {
                            click: function($event) {
                              _vm.rating = "q"
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                            Questionable\n                        "
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "toggle-button rating-s",
                    class: { active: _vm.rating === "s" },
                    on: {
                      click: function($event) {
                        _vm.rating = "s"
                      }
                    }
                  },
                  [_vm._v("Safe\n                    ")]
                )
              ],
              2
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid come-together-now" }, [
          _vm._m(7),
          _vm._v(" "),
          _c("div", { staticClass: "col2" }, [
            _c(
              "div",
              { staticClass: "box-section upload_preview_container in-editor" },
              [
                _c("div", { staticClass: "upload_preview_dims" }, [
                  _vm._v(_vm._s(_vm.previewDimensions))
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "upload_preview_img",
                  staticStyle: { "max-width": "100%" },
                  attrs: { src: _vm.previewURL, referrerpolicy: "no-referrer" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showErrors && _vm.notEnoughTags,
                    expression: "showErrors && notEnoughTags"
                  }
                ],
                staticClass: "box-section sect_red"
              },
              [
                _vm._v("\n                    You must provide at least "),
                _c("b", [_vm._v(_vm._s(4 - _vm.tagCount))]),
                _vm._v(
                  " more tags. Tags in other sections count\n                    towards this total.\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.preview.show,
                    expression: "!preview.show"
                  }
                ]
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tagEntries.other,
                      expression: "tagEntries.other"
                    }
                  ],
                  ref: "otherTags",
                  staticClass: "tag-textarea",
                  attrs: {
                    id: "post_tags",
                    rows: "5",
                    "data-autocomplete": "tag-edit"
                  },
                  domProps: { value: _vm.tagEntries.other },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.tagEntries, "other", $event.target.value)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.preview.show,
                    expression: "preview.show"
                  }
                ]
              },
              [
                _c("tag-preview", {
                  attrs: {
                    tags: _vm.preview.tags,
                    loading: _vm.preview.loading
                  },
                  on: { close: _vm.previewFinalTags }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "related-tag-functions" }, [
              _vm._v("\n                    Related:\n                    "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.findRelated()
                    }
                  }
                },
                [_vm._v("Tags")]
              ),
              _vm._v(" |\n                    "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.findRelated("artist")
                    }
                  }
                },
                [_vm._v("Artists")]
              ),
              _vm._v(" |\n                    "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.findRelated("copyright")
                    }
                  }
                },
                [_vm._v("Copyrights")]
              ),
              _vm._v(" |\n                    "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.findRelated("char")
                    }
                  }
                },
                [_vm._v("Characters")]
              ),
              _vm._v(" |\n                    "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.findRelated("species")
                    }
                  }
                },
                [_vm._v("Species")]
              ),
              _vm._v(" |\n                    "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.findRelated("meta")
                    }
                  }
                },
                [_vm._v("Metatags")]
              ),
              _vm._v(" |\n                    "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.previewFinalTags($event)
                    }
                  }
                },
                [_vm._v("Preview Final Tags")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-grid border-bottom over-me" },
          [
            _vm.relatedTags.length
              ? _c("related-tags", {
                  attrs: {
                    tags: _vm.tagsArray,
                    related: _vm.relatedTags,
                    loading: _vm.loadingRelated
                  },
                  on: { "tag-active": _vm.pushTag }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid border-bottom" }, [
          _vm._m(8),
          _vm._v(" "),
          _c("div", { staticClass: "col2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.parentID,
                  expression: "parentID",
                  modifiers: { number: true }
                }
              ],
              attrs: { type: "number", placeholder: "Ex. 12345" },
              domProps: { value: _vm.parentID },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.parentID = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _vm.allowLockedTags
          ? _c("div", { staticClass: "flex-grid border-bottom" }, [
              _vm._m(9),
              _vm._v(" "),
              _c("div", { staticClass: "col2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lockedTags,
                      expression: "lockedTags"
                    }
                  ],
                  attrs: { type: "text", "data-autocomplete": "tag-query" },
                  domProps: { value: _vm.lockedTags },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.lockedTags = $event.target.value
                    }
                  }
                })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.allowRatingLock
          ? _c("div", { staticClass: "flex-grid border-bottom" }, [
              _vm._m(10),
              _vm._v(" "),
              _c("div", { staticClass: "col2" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ratingLocked,
                        expression: "ratingLocked"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.ratingLocked)
                        ? _vm._i(_vm.ratingLocked, null) > -1
                        : _vm.ratingLocked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.ratingLocked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.ratingLocked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.ratingLocked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.ratingLocked = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" Lock Rating")
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid border-bottom" }, [
          _vm._m(11),
          _vm._v(" "),
          _c("div", { staticClass: "col2" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description"
                }
              ],
              staticClass: "tag-textarea",
              attrs: { id: "post_description", rows: "5" },
              domProps: { value: _vm.description },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.description = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid" }, [
          _c("div", { staticClass: "col" }),
          _vm._v(" "),
          _c("div", { staticClass: "col2" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.preventUpload && _vm.showErrors,
                    expression: "preventUpload && showErrors"
                  }
                ],
                staticClass: "box-section sect_red"
              },
              [
                _vm._v(
                  "\n                    Unmet requirements above prevent the submission of the post.\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.submitting,
                    expression: "submitting"
                  }
                ],
                staticClass: "box-section sect_green"
              },
              [
                _vm._v(
                  "\n                    Submitting your post, please wait.\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "box-section sect_red"
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.error) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.duplicateId,
                    expression: "duplicateId"
                  }
                ],
                staticClass: "box-section sect_red"
              },
              [
                _vm._v("\n                    Post is a duplicate of "),
                _c("a", { attrs: { href: _vm.duplicatePath } }, [
                  _vm._v("post #" + _vm._s(_vm.duplicateId) + ".")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                attrs: {
                  disabled:
                    (_vm.showErrors && _vm.preventUpload) || _vm.submitting,
                  accesskey: "s"
                },
                on: { click: _vm.submit }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.submitting ? "Uploading..." : "Upload") +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "col box-section",
        staticStyle: { "margin-left": "10px", padding: "10px" },
        attrs: { id: "preview-sidebar" }
      },
      [
        _c("div", { staticClass: "upload_preview_container in-sidebar" }, [
          _c("div", { staticClass: "upload_preview_dims" }, [
            _vm._v(_vm._s(_vm.previewDimensions))
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "upload_preview_img",
            staticStyle: { "max-width": "100%" },
            attrs: { src: _vm.previewURL },
            on: { load: _vm.updatePreviewDims, error: _vm.previewError }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "label",
        { staticClass: "section-label", attrs: { for: "post_file" } },
        [_vm._v("File")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hint" }, [
        _c("a", { attrs: { href: "/help/supported_filetypes" } }, [
          _vm._v("Supported Formats")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "label",
        { staticClass: "section-label", attrs: { for: "post_sources" } },
        [_vm._v("Sources")]
      ),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "You should include: A link to the artists page where this was obtained, and a link to the\n                    submission page where this image was obtained. No available source should ONLY be used if the\n                    content has never been posted online anywhere else.\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("label", { staticClass: "section-label", attrs: { for: "names" } }, [
        _vm._v("Artists")
      ]),
      _vm._v(" "),
      _c("div", [
        _c("a", { attrs: { href: "/forum_topics/23553" } }, [
          _vm._v("How do I tag an artist?")
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("Please don't use "),
        _c("a", { attrs: { href: "/wiki_pages/anonymous_artist" } }, [
          _vm._v("anonymous_artist")
        ]),
        _vm._v(" or "),
        _c("a", { attrs: { href: "unknown_artist" } }, [
          _vm._v("unknown_artist")
        ]),
        _vm._v(" tags unless they fall under those definitions on the wiki.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "label",
        { staticClass: "section-label", attrs: { for: "post_sex_tags" } },
        [_vm._v("Characters")]
      ),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                        Select (and write in) all that apply. Character sex is based only on what is visible in the\n                        image.\n                    "
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("a", { attrs: { href: "/wiki_pages/tag_what_you_see" } }, [
          _vm._v(
            "\n                        Outside information or other images should not be used when deciding what tags are used.\n                    "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("label", { staticClass: "section-label" }, [
        _vm._v("Body Types and Species")
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "One listed body type per visible character, listed options are mutually exclusive."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("label", { staticClass: "section-label" }, [
        _vm._v("Contentious Content")
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                        Fetishes or subjects that other users may find extreme or objectionable.\n                        These allow users to find or blacklist content with ease. Make sure that you are tagging\n                        these upon initial upload.\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("label", { staticClass: "section-label" }, [_vm._v("Rating")]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "Explicit tags include sex, pussy, penis, masturbation, fellatio, etc.\n                    ("
        ),
        _c("a", { attrs: { href: "/help/ratings", target: "_blank" } }, [
          _vm._v("help")
        ]),
        _vm._v(")\n                ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "label",
        { staticClass: "section-label", attrs: { for: "post_tags" } },
        [_vm._v("Other Tags")]
      ),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n                    Separate tags with spaces. ("),
        _c("a", { attrs: { href: "/help/tags", target: "_blank" } }, [
          _vm._v("help")
        ]),
        _vm._v(")\n                ")
      ]),
      _vm._v(" "),
      _c("div", [
        _c("a", { attrs: { href: "/wiki_pages/tag_what_you_see" } }, [
          _vm._v(
            "\n                    Outside information or other images should not be used when deciding what tags are used.\n                  "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("label", { staticClass: "section-label" }, [_vm._v("Parent Post ID")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("label", { staticClass: "section-label" }, [_vm._v("Locked Tags")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("label", { staticClass: "section-label" }, [_vm._v("Lock Rating")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "label",
        { staticClass: "section-label", attrs: { for: "post_description" } },
        [_vm._v("Description")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }