var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "toggle-button",
      class: { active: _vm.value },
      on: {
        click: function($event) {
          _vm.value = !_vm.value
        }
      }
    },
    [_vm._v(_vm._s(_vm.check.name))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }