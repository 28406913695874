var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.realValue,
          expression: "realValue"
        }
      ],
      staticClass: "upload-source-input",
      attrs: { type: "text", size: "50" },
      domProps: { value: _vm.realValue },
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.add($event)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.realValue = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _vm.index !== 0
      ? _c("button", { on: { click: _vm.remove } }, [_vm._v("-")])
      : _vm._e(),
    _vm._v(" "),
    _vm.last && _vm.index < 9
      ? _c("button", { on: { click: _vm.add } }, [_vm._v("+")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }